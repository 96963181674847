<template>
  <div class="subjectList">
    <div
      v-for="(item, index) in subjectList"
      :key="index"
      :class="[value == item.id ? 'active' : 'subject', 'subject']"
      @click="handleClick(item)"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>
import { getstatsubjectoption } from "@/core/api/academic/common";
export default {
  name: "SubjectList",
  model: {
    prop: "value",
    event: "subjectChange",
  },
  props: {
    hasLevelSegment: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Number, String],
      default: 1,
    },
    hasTotal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      subjectList: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await getstatsubjectoption({
        id: this.$route.query.id,
        hasTotal: this.hasTotal ? 1 : 0,
        type: "union",
      });
      if (this.hasLevelSegment) {
        this.subjectList = res.data.data.filter(
          (item) => item.hasLevelSegment == 1
        );
      } else {
        this.subjectList = res.data.data;
      }
      if (this.subjectList.length) {
        this.$emit("subjectChange", res.data.data[0].id);
        this.$emit("change");
      }
    },
    handleClick(item) {
      this.$emit("subjectChange", item.id);
      this.$emit("change");
    },
  },
};
</script>
<style lang="scss" scoped>
.subjectList {
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .subject {
    color: #0a1119;
    font-size: 12px;
    cursor: pointer;
    font-weight: 400;
    margin-right: 40px;
  }
  .active {
    color: #2474ed;
  }
}
</style>
